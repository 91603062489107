<template>
  <div >
    <div class="form-box mb-15" v-if="merchantList.after">
      <div style="font-weight: 500" class="mb-10">
        售后单号：{{ merchantList.after.as_no }}
      </div>
      <el-row :gutter="20" class="mb-10">
        <el-col :span="8">
          <div v-if="merchantList.after && merchantList.after.member">
            客户姓名：{{ merchantList.after.member.fullname }}
          </div>
        </el-col>
        <el-col :span="8">
          <div v-if="merchantList.after && merchantList.after.member">
            客户电话：{{ merchantList.after.member.mobile }}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mb-10">
        <el-col :span="8">
          <div
            v-if="
              merchantList.after &&
              merchantList.after.order.store_delivery &&
              merchantList.after.order.store_delivery.logistics
            "
          >
            城市仓：{{ merchantList.after.order.store_delivery.logistics.name }}
          </div>
        </el-col>
        <el-col :span="12">
          <div
            v-if="merchantList.after && merchantList.after.order.store_delivery"
          >
            自提点信息：{{ merchantList.after.order.store_delivery.name
            }}<span v-if="merchantList.after.order.store_delivery.address">
              ，{{ merchantList.after.order.store_delivery.address }}</span
            ><span v-if="merchantList.after.order.store_delivery.mobile"
              >，{{ merchantList.after.order.store_delivery.mobile }}</span
            >
          </div>
        </el-col>
      </el-row>
      <div style="font-weight: 500">
        订单号：{{ merchantList.after.order_no }}
      </div>
    </div>
    <div class="auto-table-flex mb-30">
      <el-table
        :data="goodsData"
        v-loading="loading"
        :border="true"
        ref="goodsTable"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <!-- <el-table-column type="index" width="80" align="center" label="序号">
                        </el-table-column> -->
        <el-table-column
          prop="goodsName"
          width="160"
          align="center"
          label="主图"
        >
          <template slot-scope="scope">
            <el-image
              class="kv pointer"
              :src="scope.row.product.kv"
              :preview-src-list="[scope.row.product.kv,...scope.row.product.srp_pic.split(',')]"
            >
            </el-image>
            <div class="font" style="color: #13ae67">点击查看详细图片</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          align="center"
          label="商品信息"
          width="300"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.product"
              class="d-flex flex-column info"
              style="align-items: flex-start"
            >
              <div class="pb">
                {{ $empty.empty(scope.row.product.title) }} ({{
                  $empty.empty(scope.row.product.sku_code)
                }})
              </div>
              <div class="py" style="color: #f93a16">
                开市价：{{
                  tool.toDecimal2(
                    (scope.row.product.price * 10000) / 100 / 10000
                  )
                }}元
              </div>
              <div class="py">
                毛重/毛单价：{{
                  $empty.empty(scope.row.order_detail.gross_weight)
                }}斤【{{
                  tool.toDecimal2(
                    (scope.row.order_detail.gross_weight_price * 10000) /
                      100 /
                      10000
                  )
                }}元/斤】
              </div>
              <div class="py d-flex a-center">
                净重/净单价：{{
                  $empty.empty(scope.row.order_detail.weight)
                }}斤【{{
                  tool.toDecimal2(
                    (scope.row.order_detail.weight_price * 10000) / 100 / 10000
                  )
                }}元/斤】
                <img
                  v-if="scope?.row?.order_detail?.weight_diff"
                  @click="onHandleWeight(scope.row.order_detail.weight_diff)"
                  alt=""
                  src="@/assets/image/tip.png"
                  style="width: 14px; height: 14px"
                />
              </div>
              <div
                class="pt"
                v-if="
                  scope.row.product &&
                  scope.row.product.supplier &&
                  scope.row.product.buyer
                "
              >
                主供货商：{{ scope.row.product.supplier.title }}({{
                  scope.row.product.buyer.fullname
                }})
              </div>
            </div>
            <div v-else>—</div>
          </template>
        </el-table-column>
        <el-table-column prop="id_card" align="center" label="订货数量">
          <template slot-scope="scope">
            <span v-if="scope.row.order_detail">{{
              $empty.empty(scope.row.order_detail.qty)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="id_card" align="center" label="业务总净重">
          <template slot-scope="scope">
            <span v-if="scope.row.order_detail">{{
              $empty.empty(
                (scope.row.order_detail.weight *
                  10000 *
                  scope.row.order_detail.qty) /
                  10000
              )
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="freight_amt" align="center" label="商品总额">
          <template slot-scope="scope">
            <span v-if="scope.row.order_detail"
              >{{
                tool.toDecimal2(
                  (scope.row.order_detail.price *
                    10000 *
                    scope.row.order_detail.qty) /
                    10000 /
                    100
                )
              }}元</span
            >
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="purchase_amt" align="center" label="服务费">
          <template slot-scope="scope">
            <span
              >{{
                tool.toDecimal2(
                  (scope.row.order_detail.purchase_amt * 10000) / 100 / 10000
                )
              }}元</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="freight_amt" align="center" label="运费">
          <template slot-scope="scope">
            <span
              >{{
                tool.toDecimal2(
                  (scope.row.order_detail.freight_amt * 10000) / 100 / 10000
                )
              }}元</span
            >
          </template>
        </el-table-column>

        <el-table-column prop="finance_amt" align="center" label="金融手续费">
          <template slot-scope="scope">
            <span
              >{{
                tool.toDecimal2(
                  (scope.row.order_detail.finance_amt * 10000) / 100 / 10000
                )
              }}元</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="finance_amt" align="center" label="总金额">
          <template slot-scope="scope">
            <span v-if="scope.row.order_detail">
              {{
                tool.toDecimal2(
                  scope.row.order_detail.finance_amt * 10000 +
                    scope.row.order_detail.purchase_amt * 10000 +
                    scope.row.order_detail.freight_amt * 10000 +
                    scope.row.order_detail.price *
                      10000 *
                      scope.row.order_detail.qty
                    ? (scope.row.order_detail.finance_amt * 10000 +
                        scope.row.order_detail.purchase_amt * 10000 +
                        scope.row.order_detail.freight_amt * 10000 +
                        scope.row.order_detail.price *
                          10000 *
                          scope.row.order_detail.qty) /
                        10000 /
                        100
                    : 0
                )
              }}元</span
            >
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="reason"
          align="center"
          label="供货单号"
          width="160"
        >
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.order_detail && scope.row.order_detail.supplier_order
              "
              >{{
                $empty.empty(
                  scope.row.order_detail.supplier_order.supplier_order_no
                )
              }}</span
            >
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reason" align="center" label="实际供货商">
          <template slot-scope="scope">
            <span v-if="scope.row.product && scope.row.product.supplier">{{
              $empty.empty(scope.row.product.supplier.title)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reason" align="center" label="业务员">
          <template slot-scope="scope">
            <span v-if="scope.row.product && scope.row.product.buyer">{{
              $empty.empty(scope.row.product.buyer.fullname)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reason" align="center" label="品控缺货">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.order_detail &&
                scope.row.order_detail.order_detail_delivery
              "
              >{{
                $empty.empty(
                  scope.row.order_detail.order_detail_delivery.stockout_number
                )
              }}</span
            >
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reason" align="center" label="缺货毛重">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.order_detail &&
                scope.row.order_detail.order_detail_delivery
              "
              >{{
                $empty.empty(
                  (scope.row.order_detail.order_detail_delivery
                    .stockout_number *
                    1000 *
                    scope.row.order_detail.gross_weight *
                    1000) /
                    1000000
                )
              }}斤</span
            >
            <span v-else>—</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-bottom: 20px;color: #f93a16;" v-if="merchantList.after&&merchantList.after.self_product_name">
        该用户近 7 日有自采：{{ merchantList.after.self_product_name }}
    </div>
    <!-- 售后率表格 start -->
    <el-table
      :data="afterTableData"
      :border="true"
      v-loading="afterLoading"
      ref="afterTable"
      :header-cell-style="{
        color: '#333333',
        background: '#EFF6FF',
      }"
    >
      <el-table-column prop="goodsName" align="center" label="">
        <template slot-scope="scope">
          <div v-if="scope.row.type == AFTER_SHOW_TYPE_ENUM['1'].value">
            用户：{{ scope.row.member.fullname }} ({{
              scope.row.member.mobile
            }})
          </div>
          <div v-if="scope.row.type == AFTER_SHOW_TYPE_ENUM['2'].value">
            供应商：{{ scope.row.supplier.title }}
          </div>
          <div v-if="scope.row.type == AFTER_SHOW_TYPE_ENUM['3'].value">
            货号：{{ scope.row.sku_code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="percent_15"
        align="center"
        label="15天售后率"
        width="300"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.percent_15">
            {{ (scope.row.percent_15 * 100).toFixed(2) }}%
          </div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="percent_30" align="center" label="30天售后率">
        <template slot-scope="scope">
          <div v-if="scope.row.percent_30">
            {{ (scope.row.percent_30 * 100).toFixed(2) }}%
          </div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="total_aftersale_amt"
        align="center"
        label="总售后退款金额"
      >
        <template slot-scope="scope">
          <span
            >{{
              tool.toDecimal2(
                (scope.row.total_aftersale_amt * 10000) / 100 / 10000
              )
            }}元</span
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 30px"></div>
    <!-- 售后率表格 end -->
     <div style="color: red;">
      {{ merchantList.after.fleet }}
     </div>
    <div
      class="pb-2 mb-15"
      style="border-bottom: 1px solid #eeeeee"
      v-if="merchantList.aftersale_detail"
    >
      <div class="mb-10">
        商品标签：{{merchantList?.aftersale_detail?.product?.promotion_tag_name|| "—" }}
      </div>
      <div class="mb-10">
       <span style="margin-right: 100px;"> 等级：{{ merchantList?.aftersale_detail?.product?.grade || "—" }}</span>
       <span style="margin-right: 100px;"> 最小甜度：{{ merchantList?.aftersale_detail?.product?.min_se || "—" }}</span>
       <span> 最大甜度：{{ merchantList?.aftersale_detail?.product?.max_se || "—" }}</span>
      </div>
      <div class="mb-10">
        规格：{{ merchantList?.aftersale_detail?.product?.spec || "—" }}
      </div>
      <div class="mb-10">
        不良率：{{
          merchantList.aftersale_detail.product.reject
            ? merchantList.aftersale_detail.product.reject + "%"
            : "—"
        }}
      </div>
      <div class="mb-10">
        免赔情况：{{
          merchantList.aftersale_detail.product.ded
            ? merchantList.aftersale_detail.product.ded
            : "—"
        }}
      </div>
      <div class="mb-10">
        可申请售后条件：{{
          merchantList.aftersale_detail.product.as_cond
            ? merchantList.aftersale_detail.product.as_cond
            : "—"
        }}
      </div>
      <div class="mb-10">
        简介：{{
          merchantList.aftersale_detail.product.intro
            ? merchantList.aftersale_detail.product.intro
            : "—"
        }}
      </div>

      <div class="mb-10">
        售后原因：{{ aftertype[merchantList.aftersale_detail.reason_type] }}
      </div>
      <div class="mb-10">
        报损重量：{{ merchantList.aftersale_detail.product_weight }}斤
      </div>
      <div class="d-flex mb-10">
        <div style="white-space: nowrap">问题描述：</div>
        <div>{{ merchantList.aftersale_detail.desc }}</div>
      </div>
      <div class="d-flex mb-10">
        <div>商品包装图：</div>
        <div v-if="merchantList?.aftersale_detail?.product?.pack_pic">
          <el-image
            class="after-image"
            :src="img"
            v-for="(
              img, idx
            ) in merchantList?.aftersale_detail?.product?.pack_pic.split(',')"
            :key="idx"
            :preview-src-list="
              merchantList?.aftersale_detail?.product?.pack_pic.split(',')
            "
          ></el-image>
        </div>
        <div v-else>—</div>
      </div>
      <div class="d-flex mb-10">
        <div>商品介绍视频：</div>
        <div v-if="merchantList?.aftersale_detail?.product?.srp_video">
          <video
            v-for="(
              video, idx
            ) in merchantList?.aftersale_detail?.product?.srp_video.split(',')"
            :key="idx"
            ref="videoPlayer"
            :src="video"
            :autoplay="false"
            :controls="true"
            class="video pointer"
          ></video>
        </div>

        <div v-else>—</div>
      </div>
      <!-- <div class="d-flex mb-10">
          <div>图片证据：</div>
          <div v-if="merchantList?.aftersale_detail?.attach_pic">
            <el-image
              class="after-image"
              :src="img"
              :key="index"
              v-for="(
                img, index
              ) in merchantList?.aftersale_detail?.attach_pic.split(',')"
              :preview-src-list="
                merchantList?.aftersale_detail?.attach_pic.split(',')
              "
            ></el-image>
          </div>
        </div>
        <div class="d-flex mb-10">
          <div>视频证据：</div>
          <video
            v-if="merchantList?.aftersale_detail?.attach_video"
            ref="videoPlayer"
            :src="merchantList?.aftersale_detail?.attach_video"
            :autoplay="false"
            :controls="true"
            class="video pointer"
          ></video>
          <div v-else>—</div>
        </div> -->
      <div
        class="mb-10"
        style="color: #f93a16; font-size: 20px"
        v-if="
          merchantList?.aftersale_detail?.order_detail?.supplier_order
            .audit_status == REVIEW_STATUS_ENUM['notYetPassed'].value
        "
      >
        品控状态:{{
          Object.values(REVIEW_STATUS_ENUM).find(
            (item) =>
              item.value ==
              merchantList?.aftersale_detail?.order_detail?.supplier_order
                .audit_status
          ).label
        }}
      </div>
      <div
        class="mb-10"
        style="color: #f93a16; font-size: 20px"
        v-if="
          Number(
            merchantList?.aftersale_detail?.order_detail?.supplier_order
              .partial_quality_control_quantity
          ) > 0 &&
          Number(
            merchantList?.aftersale_detail?.order_detail?.supplier_order
              .partial_quality_control_quantity
          ) <
            Number(
              merchantList?.aftersale_detail?.order_detail?.supplier_order
                .product_number
            )
        "
      >
        供应商未完全品控：供货单数量：{{
          merchantList?.aftersale_detail?.order_detail?.supplier_order
            .product_number
        }}，品控：{{
          merchantList?.aftersale_detail?.order_detail?.supplier_order
            .partial_quality_control_quantity
        }}
      </div>
    </div>
    <div class="mb-15" style="border-bottom: 1px solid #eeeeee">
      <el-steps direction="vertical">
        <template v-for="(item, index) in step" >
        
            <el-step :title="item.step_name">
              <div slot="icon">
                <img class="activeimg" src="@/assets/image/active.png" alt="" />
              </div>
              <div slot="description" class="mb-50">
                <template
                  v-if="
                    item.aftersale_step_history &&
                    item.aftersale_step_history.params
                  "
                >
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['mobile']"
                  >
                    联系电话：{{ item.aftersale_step_history.params["mobile"] }}
                  </div>
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['reason']"
                  >
                    售后(报损)原因：{{
                      item.aftersale_step_history.params["reason"]
                    }}
                  </div>

                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['weight']"
                  >
                    售后(报损)重量：{{
                      item.aftersale_step_history.params["weight"]
                    }}
                  </div>
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['status']"
                  >
                    处理意见：{{ item.aftersale_step_history.params["status"] }}
                  </div>
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['amount']"
                  >
                    退款金额：{{ item.aftersale_step_history.params["amount"] }}
                  </div>
                  <!-- <div style="color: #777777;" class="mb" v-if="item.aftersale_step_history.params['amount']">
                                      扣款金额：{{ item.aftersale_step_history.params['amount'] }}
                                  </div> -->
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['reason_title']"
                  >
                    原因选择：{{
                      item.aftersale_step_history.params["reason_title"]
                    }}
                  </div>
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['reason_content']"
                  >
                    {{ item.aftersale_step_history.params["reason_content"] }}
                  </div>
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="item.aftersale_step_history.params['remark']"
                  >
                    判定说明：{{ item.aftersale_step_history.params["remark"] }}
                  </div>
                  <div
                    style="color: #777777"
                    class="mb"
                    v-if="
                      item.aftersale_step_history.params['intervene_remark']
                    "
                  >
                    介入原因：{{
                      item.aftersale_step_history.params["intervene_remark"]
                    }}
                  </div>
                  <div
                    class="mb d-flex"
                    v-if="item.aftersale_step_history.params['attach_pic']"
                  >
                    <div style="color: #777777">图片证据：</div>
                    <el-image
                      :preview-src-list="
                        item.aftersale_step_history.params['attach_pic'].split(
                          ','
                        )
                      "
                      class="mr-1 after-image"
                      :key="idx"
                      :src="img"
                      v-for="(img, idx) in item.aftersale_step_history.params[
                        'attach_pic'
                      ].split(',')"
                    ></el-image>
                  </div>
                  <div
                    class="d-flex a-center mb"
                    v-if="item.aftersale_step_history.params['attach_video']"
                  >
                    <div style="color: #777777">视频证据：</div>
                    <video
                      id="myVideo"
                      v-for="(
              video, idxs
            ) in item.aftersale_step_history.params['attach_video'].split(',')"
            :key="idxs"
                      :src="video"
                      :autoplay="false"
                      :controls="true"
                      class="video pointer"
                    ></video>
                  </div>
                </template>
                <div style="color: #999999">
                  {{
                    item.handle_type == 40 ? item.create_time : item.update_time
                  }}
                </div>
              </div>
            </el-step>
            <el-step v-if="index == step.length - 1" title="">
              <div slot="icon"></div>
            </el-step>
        </template>
      </el-steps>
    </div>

    <!-- 修改重量记录 start -->
    <fix-weight-log ref="refWeightLog"></fix-weight-log>
    <!-- 修改重量记录 end -->
  </div>
</template>
  
  <script>
import fixWeightLog from "../modules/fix-weight-log/index";
import { REVIEW_STATUS_ENUM, AFTER_SHOW_TYPE_ENUM } from "../utils/enum";
export default {
  components: {
    fixWeightLog,
  },
  data() {
    return {
        AFTER_SHOW_TYPE_ENUM,
      REVIEW_STATUS_ENUM,
      afterTableData: [],
      goodsData: [],
      loading: true,
      afterLoading: true,
      aftertype: {
        10: "运损——外包装损坏，如压坏、挤压、漏洞、变形等",
        20: "缺货——到货件数小于购买件数",
        30: "质量问题——坏果、标品漏坏、商品变质、涨袋、破损、过期等的问题",
        40: "货不对板——到货商品与商品描述不符",
        50: "缺斤少两",
        60: "差价",
      },
      merchantList: {},
      step: "",
    };
  },
  created() {},
  methods: {
    hqinfostep(e) {
      this.step = e;
    },
    hqinfomerchantList(e) {
      this.merchantList = e;
    },
    hqinfoafterTableData(e) {
      this.afterTableData = e;
      this.afterLoading=false;

    },
    hqinfogoodsData(e) {
      this.goodsData = e;
      this.loading = false;

    },
    // 修改毛重 净重 记录
    onHandleWeight(data) {
      this.$refs.refWeightLog.onInitData({ ...data, isCount: true });
    },
  },
};
</script>
  
  <style lang="less">

  .kv {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .mb {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .form-box {
    .el-form-item {
      margin-bottom: 0;
    }
  }

  .video {
    width: 230px;
    height: 230px;
  }

  .after-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;

    img {
      object-fit: fill;
    }
  }
  .after-image:last-of-type {
    margin-right: 0;
  }

  .el-step__icon.is-text {
    border: 0;
  }

 .el-step__title.is-wait {
    color: #333333;
  }

  .activeimg {
    width: 16px;
    height: 16px;
  }
  .textWrap {
    width: 275px !important;
  }
  .specialWrap {
    .el-input__inner {
      color: #f93a16;
    }
  }
  .textareaWrap {
    .el-textarea__inner {
      height: 87px !important;
    }
  }
  .responsibilityType {
    width: 80px;
    font-size: 16px;
    font-weight: 500;
    color: #fb8638;
  }
  .tipsWrap {
    font-weight: 500;
    color: #38b2fb;
    margin-left: 8px;
  }

  .checkNewBtn {
    cursor: pointer;
    // width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px;
    padding: 0px 7px;
    border: 1px solid #333333;
  }

  .checkNewBtn:last-of-type {
    margin-right: 0;
  }

  .checkNewBtn-active {
    background-color: #fb8638;
    border: 1px solid #fb8638;
    color: #ffffff;
  }

  .font {
    font-size: 12px;
  }

  .fy2 {
    left: 250px;
  }

  .pt {
    padding-top: 5px;
  }

  .py {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pb {
    padding-bottom: 5px;
  }

  .border-b {
    border-bottom: 1px solid #ebeef5;
  }

  .video {
    width: 230px;
  }

  .el-input.is-disabled .el-input__inner,
  .el-textarea.is-disabled .el-textarea__inner {
    color: #333333;
  }

  .remark-wrapper {
    padding: 20px 60px;
    border: 1px solid #dbe0e4;
  }

  .input-width {
    width: 200px;
  }

  .sale-font {
    font-size: 15px;
  }



  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #13AE67;
  //   font-size: 20px;
  // }

</style>
  